<template>
    <div class="home">
        <div class="head-img">
            <img src="@/assets/home/melves_banner.jpg" />
        </div>
        <div class="body-info">
            <div class="title">
                基础信息
            </div>
            <div class="content">
                <van-row class="block">
                    <van-col span="24">设备名称：{{info.devName || '名称未设置'}}</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="24">设备编号：{{info.devCode}}</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="24">设备状态：{{info.devStatus === 'online' ? '在线' : info.devStatus === 'alarm' ? '告警' : '离线'}}</van-col>
                </van-row>
            </div>
            <div class="flex-between">
                <div class="title">
                    实时信息
                </div>
            </div>
            <div class="content">
                <van-row class="block">
                    <van-col span="12">空气温度：{{info.melvesStatus ? info.melvesStatus.airTemp : 0}} ℃</van-col>
                    <van-col span="12">空气湿度：{{info.melvesStatus ? info.melvesStatus.airHumi : 0}} %</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">路面温度：{{info.melvesStatus && info.melvesStatus.roadbedTemp  === -273 ? `- -` : `${info.melvesStatus && info.melvesStatus.roadbedTemp || 0} ℃`}}</van-col>
                    <van-col span="12">路面-10cm温度：{{info.melvesStatus && info.melvesStatus.road10cmTemp  === -273 ? `- -` : `${info.melvesStatus && info.melvesStatus.road10cmTemp || 0} ℃`}}</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">路面状态：{{info.melvesStatus && info.melvesStatus.roadState == 1 ? '潮湿' : info.melvesStatus && info.melvesStatus.roadState == 2 ? '积水' :info.melvesStatus && info.melvesStatus.roadState == 3 ? '积雪' : info.melvesStatus && info.melvesStatus.roadState == 4 ? '结冰' :'干燥'}}</van-col>
                    <van-col span="12">路面覆盖厚度：{{info.melvesStatus ? info.melvesStatus.coverThickness : 0}} mm</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">能见度：{{info.melvesStatus ? info.melvesStatus.visibility : 0}} m</van-col>
                    <van-col span="12">大气压：{{info.melvesStatus ? info.melvesStatus.atmoPressure : 0}} Pa</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">PM2.5：{{info.melvesStatus ? info.melvesStatus.pm2p5 : 0}} ug/m³</van-col>
                    <van-col span="12">PM10：{{info.melvesStatus ? info.melvesStatus.pm10 : 0}} ug/m³</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">PM1.0：{{info.melvesStatus ? info.melvesStatus.pm1p0 : 0}} ug/m³</van-col>
                    <!--  <van-col span="12">光照强度：{{info.melvesStatus ? info.melvesStatus.illuminance : 0}} lux</van-col> -->
                </van-row>
                
                <van-row class="block">
                    <van-col span="24">更新时间：{{info.melvesStatus ? info.melvesStatus.recordTime :'0000-00-00 00:00:00'}}</van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                melvesStatus:{}
            }
        }
    },
    mounted(){
        this.getNewInfo()
    },
    methods:{
        // 获取当前设备最新信息
        getNewInfo(){
            this.info = JSON.parse(window.sessionStorage.getItem('currentMelvesInfo'));
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
    .head-img{
        width: 100%;
        padding: 15px 15px 0 15px;
        box-sizing: border-box;
        img{
            width: 100%;
            border-radius: 10px;
        }
    }
    .body-info{
        padding: 10px 20px;
        .title{
            border-left: 4px solid #23b593;
            padding-left: 10px;
            margin: 12px 0;
            text-align: left;
            font-weight: bolder;
            font-size: .95rem;
        }
        .content{
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            background-color: #13a597;
            text-align: left;
            padding: 0 20px;
            font-size: 0.9rem;
            color: #fff;
            box-shadow: 2px 2px 2px #efefef;
            .block{
                padding:8px 0;
            }
        }
    }
    .footer{
        display: flex;
        justify-content: center;
        margin: 20px;
        .btn{
            background: #23b593;
            border-radius: 10px;
            color: #fff;
            font-size: .9rem;
            line-height: 2;
            width: 40%;
            text-align: center;
            &:active{
                opacity: .7;
            }
        }
    }
    .flex-between{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time{
            font-size: .78rem;
            color: #adadad;
        }
    }
}
</style>